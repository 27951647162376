<template>
  <div>
    <modal-box
      :is-active="isModalActive"
      :trash-object-name="trashObjectName"
      @confirm="trashConfirm"
      @cancel="trashCancel"
    />
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      {{ heroTitle }}
      <router-link slot="right" :to="{ name: 'tariffs' }" class="button">
        Manage Tariffs
      </router-link>
    </hero-bar>
    <section class="section is-main-section">
      <tiles>
        <card-component
          :title="formCardTitle"
          icon="account-edit"
          class="tile is-child"
        >
          <form @submit.prevent="submit">
            <b-field label="ID" horizontal>
              <b-input
                v-model="form.id"
                placeholder="ID"
                required
              />
            </b-field>
            <b-field horizontal label="Total Amt">
              <b-input v-model="form.total_amount"  />
            </b-field>
            <b-field horizontal label="Total with MFC">
              <b-input v-model="form.total_with_mfc"  />
            </b-field>
            <b-field horizontal label="ED Charges">
              <b-input v-model="form.ed_charges"  />
            </b-field>
            <b-field horizontal label="Total Units">
              <b-input v-model="form.total_units"  />
            </b-field>
            <b-field horizontal label="Final Amount">
              <b-input v-model="form.final_amount"  />
            </b-field>
            <b-field label="Category" horizontal v-if="mechanics.length">
              <b-select placeholder="Category" v-model="form.mechanic_id">
                  <option v-for="p in mechanics" :key="p.value" :value="p.value">{{ p.text }}</option>
              </b-select>
            </b-field>
            <hr />
            <b-field horizontal>
              <b-button
                type="is-primary"
                :loading="isLoading"
                native-type="submit"
                >Submit</b-button
              >
            </b-field>
          </form>
        </card-component>
        <card-component
          v-if="isProfileExists"
          title="Tariff Details"
          icon="account"
          class="tile is-child"
          header-icon="delete"
          print-icon="cloud-print-outline"
          @header-icon-click="trashModal(form)"
          @print-icon-click="onPrintIconClick(form)"
        >
          <b-field label="Id">
            <b-input :value="form.id" custom-class="is-static" readonly />
          </b-field>
          <b-field field="emp_num" label="Emp Num">
              <span v-if="form.details.user.is_vacant==1">
                    VACANT
              </span>
              <span v-else>
                  <router-link :to="{name:'consumer.edit', params: {id: form.details.user.id}}">
                    {{ form.details.user.emp_num }}
                  </router-link>
              </span>
          </b-field>
          <b-field field="name" label="Name">
              <span>
                  <router-link :to="{name:'consumer.edit', params: {id: form.details.user.id}}">
                    {{ form.details.user.name }}
                  </router-link>
              </span>
          </b-field>

          <hr/>
          <b-field label="Meters">
            <b-table scrollable bordered striped :data="form.meters" :columns="[
                    {
                        field: 'mnum',
                        label: 'Meter Number',
                        width: '40',
                        numeric: true
                    },
                    {
                        field: 'prev_read',
                        label: 'Prev Read',
                    },
                    {
                        field: 'pres_read',
                        label: 'Pres Read',
                    },
                    {
                        field: 'pres_status',
                        label: 'Status',
                        centered: true
                    },
                    {
                        field: 'prev_date',
                        label: 'Prev Read On',
                        centered: true
                    }
                ]"></b-table>
          </b-field>
          <hr/>
          <b-field grouped>
            <b-field label="Total Amt">
              <b-input :value="form.total_amount" custom-class="is-static" readonly />
            </b-field>
            <b-field label="Total with MFC">
              <b-input :value="form.total_with_mfc" custom-class="is-static" readonly />
            </b-field>
          </b-field>
          <b-field grouped>
            <b-field label="MFC">
              <b-input :value="form.category_mfc" custom-class="is-static" readonly />
            </b-field>
            <b-field label="ED Charges">
              <b-input :value="form.ed_charges" custom-class="is-static" readonly />
            </b-field>
          </b-field>
          <b-field grouped>
            <b-field label="Total Units">
              <b-input :value="form.total_units" custom-class="is-static" readonly />
            </b-field>
            <b-field label="Final Amount">
              <b-input :value="form.final_amount" custom-class="is-static" readonly />
            </b-field>
          </b-field>
          <b-field label="Created At">
            <b-input
              :value="form.created_at"
              custom-class="is-static"
              readonly
            />
          </b-field>
          <b-field label="Updated At">
            <b-input
              :value="form.updated_at"
              custom-class="is-static"
              readonly
            />
          </b-field>
        </card-component>
      </tiles>
    </section>
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty'
import ModalBox from '@/components/ModalBox'
import TitleBar from '@/components/TitleBar'
import debounce from 'lodash/debounce'
import HeroBar from '@/components/HeroBar'
import Tiles from '@/components/Tiles'
import CardComponent from '@/components/CardComponent'
import { mapActions, mapState } from 'vuex'
import router from '@/router'
import { apiService } from '@/services'

export default {
  name: 'TariffForm',
  components: {
    CardComponent,
    Tiles,
    HeroBar,
    TitleBar,
    ModalBox
  },
  props: {
    id: {
      type: [String, Number],
      default: null
    }
  },
  data () {
    return {
      isLoading: false,
      form: this.getClearFormObject(),
      isModalActive: false,
      trashObject: null,
      createdReadable: null,
      isProfileExists: false,
      providers: [],
      atms: [],
      calltypes: [],
      mechanics: []
    }
  },
  computed: {
    trashObjectName () {
      if (this.trashObject) {
        return `ID-${this.trashObject.id}`
      }
      return null
    },
    titleStack () {
      const lastCrumb = this.isProfileExists ? this.form.id : 'New Tariff'
      return ['Admin', 'Tariffs', lastCrumb]
    },
    heroTitle () {
      return this.isProfileExists ? `Tariff: ${this.form.id}` : 'Create Tariff'
    },
    formCardTitle () {
      return this.isProfileExists ? 'Edit Tariff' : 'New Tariff'
    },
    ...mapState('tariff', ['item', 'formError'])
  },
  watch: {
    id (newValue) {
      this.isProfileExists = false
      if (!newValue) {
        this.form = this.getClearFormObject()
      } else {
        this.getData()
      }
    }
  },
  created () {
    this.getData()
  },
  methods: {
    ...mapActions('tariff', ['getItem', 'addItem', 'updateItem', 'deleteItem']),
    ...mapActions({ showError: 'error/show' }),
    getClearFormObject () {
      return {
        id: null,
        is_active: null,
        created_at: null,
        updated_at: null
      }
    },
    onPrintIconClick (printObj) {
      window.location.href = 'https://api.ssiplengg.com/print/' + printObj.id
    },
    trashModal (trashObject) {
      this.trashObject = trashObject
      this.isModalActive = true
    },
    trashConfirm () {
      this.isModalActive = false
      this.deleteItem(this.trashObject.id).then(() => {
        this.$router.replace({ name: 'tariffs' })
      })
    },
    trashCancel () {
      this.isModalActive = false
    },
    getData () {
      if (this.$route.params.id) {
        this.getItem(this.$route.params.id).then(() => {
          if (!isEmpty(this.item)) {
            this.isProfileExists = true
            this.form = this.item
          } else {
            this.$router.push({ name: 'biller' })
          }
        })
      }
    },
    getMechanics () {
      apiService.postAll('/options/mechanics', { atm: this.form.atm }).then(response => {
        this.mechanics = response.data
      }).catch(error => {
        this.showError(error)
      })
    },
    submit () {
      this.isLoading = true
      if (!this.form.id) {
        this.addItem(this.form).then(() => {
          if (!this.formError) router.push({ name: 'tariffs' })
          this.isLoading = false
        }).catch(() => { this.isLoading = false })
      } else {
        this.updateItem(this.form).then(() => {
          this.isLoading = false
          this.form = this.item
        }).catch(() => { this.isLoading = false })
      }
    },
    search: debounce((loading, endpoint, search, vm) => {
      apiService.getAll(`/options/${escape(endpoint)}?q=${escape(search)}`).then(response => {
        vm[endpoint] = response.data
        loading(false)
      }).catch(error => {
        vm.showError(error)
      })
    }, 350)
  }
}
</script>
